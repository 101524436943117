<div class="container mentions-legales px-3 py-5">
  <p class="text-60 fw-bold" style="color: #6633CC">{{ 'LegalNotices' | translate }}</p>
  <p><span class="fw-bold">Editeur :</span> PILINK</p>
  <p>
    Le présent site internet est édité par PILINK SAS, Société par actions simplifiée, au capital
    social de 5 000 €.
  </p>
  <p>Le siège social de PILINK SAS est situé au 40 AV DU 8 MAI 1945, 94260 FRESNES.</p>
  <p>
    Immatriculée au Registre du Commerce et des Sociétés de Créteil sous le numéro SIREN 917
    589 459, Numéro individuel d'identification d'assujetti à la TVA : FR 549 175 894 59.
  </p>
  <p><span>Hébergeur :</span> Heroku Inc</p>
  <p>
    Le site internet pi-link.fr est hébergé par la société Heroku Inc., 650 7th Street, San Francisco,
    CA (tel : +33 1 (877) 563-4311)
  </p>
  <p>
    Le stockage des données personnelles des utilisateurs est exclusivement réalisé sur les centres
    de données ("clusters") de la société Amazon Inc, dont le siège social est situé 10 Terry Avenue
    North, Seattle, WA. Tous les clusters Amazon sur lesquels les données du Site sont
    stockées sont localisés dans des Etats membres de l'Union Européenne.
  </p>
  <p class="fw-bold">Directeur de publication :</p>
  <p>Steve ELANGA</p>
  <p class="fw-bold">Responsable de publication :</p>
  <p>Directeur Marketing et communication</p>
  <p class="fw-bold">Technologies utilisées</p>
  <p>
    Ce site fonctionne grâce à des logiciels libres (Express, Typescript, Mongo DB, Angular 9, Redis).
  </p>
  <p class="fw-bold">Vos données personnelles :</p>
  <p>
    Le traitement de vos données à caractère personnel est régi par notre politique de confidentialité
    conformément au Règlement Général sur la Protection des Données 2016/679 du 27 avril 2016
    (« <span class="fw-bold">RGPD</span> »).
  </p>
  <p>
    Vous disposez d'un droit d'accès à vos données personnelles, de rectification et d'effacement,
    de limitation de traitement, d'opposition dans les conditions prévues par la réglementation
    applicable, à l'adresse suivante : compliance@pi-link.fr
  </p>
  <p>
    Vous avez aussi la possibilité de vous adresser au Délégué à la Protection des Données de
    PILINK SAS à l'adresse compliance@pi-link.fr et le droit d'introduire une réclamation auprès
    de la Commission Nationale de l'Informatique et des Libertés (CNIL), autorité de contrôle en
    charge du respect des obligations en matière de données à caractère personnel.
  </p>
  <p class="fw-bold">Témoins de connexion ou « cookies »</p>
  <p>
    Le site n'utilise pas de traceurs/cookies, il s'agit de fichiers texte stockés en mémoire par votre
    navigateur et qui servent notamment à vous identifier à chaque changement de page sur le site.
  </p>
  <p class="fw-bold">Sécurité</p>
  <p>
    Ce site est protégé par un important niveau de sécurité technique et est sous surveillance
    permanente.
    Les algorithmes et mécanismes, utilisés pour protéger vos communications avec PILINK et les
    informations qui vous concernent, respectent la réglementation française en vigueur.
  </p>
</div>
